import LoadingIcon from "@svg/loadingIcon";
import { IDefaultTermInput, ITermDetailInput } from "@types";
import { commonApi } from "gate";
import Image from "next/image";
import Link from "next/link";
import { SwiperSlide } from "swiper/react";
import SwiperCore, { FreeMode, Scrollbar, Mousewheel } from "swiper";
import { useRouter } from "next/router";
import { RefObject, useEffect, useRef, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useTranslations } from "use-intl";
import SearchIcon from "@svg/SearchIcon";
import PathIcon from "@svg/PathIcon";
import CardProduct from "@elements/productCard/productCard";
import { useWindowSize } from "@hooks/useWindowsSize";
import { segmentEvent } from "@utils/segment";
import { useAppContext } from "@context/app";
import Carousel from "@elements/carousel";

interface SearchBoxProps {
  isMobile?: boolean;
}

function useOutsideAlerter(ref: RefObject<any>, callback: () => void) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
}

const SearchBox: React.FC<SearchBoxProps> = ({ isMobile }) => {
  SwiperCore.use([FreeMode, Scrollbar, Mousewheel]);
  const { locale } = useRouter();
  const { user, country } = useAppContext()
  const t = useTranslations("");
  const [data, setData] = useState<ITermDetailInput | undefined>();
  const [defaultData, setDefaultData] = useState<IDefaultTermInput[]>([]);
  const [suggestions, setSuggestions] = useState<IDefaultTermInput[]>([]);
  const [viewResult, setViewResult] = useState<boolean>(false);
  const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [openSearchBox, setOpenSearchBox] = useState<boolean>(false);
  const size = useWindowSize();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    handelClose();
  });

  const fetchTerms = () => {
    if (!showSearchBox) {
      setIsLoading(true);
      commonApi
        .getTerms()
        .fetch()
        .then((res) => {
          setDefaultData(res.data.items);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
          setShowSearchBox(true);
        });
    }
  };

  let _debounceSearch: any = undefined;

  const debounceSearch = (input: string) => {
    if (_debounceSearch) clearTimeout(_debounceSearch);
    _debounceSearch = setTimeout(() => {
      getSuggestions(input);
    }, 300);
  };

  const getSuggestions = async (input: string) => {
    if (input.length >= 3) {
      try {
        setIsLoading(true);
        const res = await commonApi.getTermSuggestion({ term: input }).fetch();
        setSuggestions(res.data.items);
        setIsLoading(false);
        setViewResult(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };
  const search = async (input: string, action?: string) => {
    if (input.length >= 3) {
      try {
        setIsLoading(true);
        commonApi.setTerm({ term: input }).fetch();
        const res = await commonApi.getTermDetail({ term: input }).fetch();
        segmentEvent("Product Searched", {
          action: action ?? "Search Box",
          search_keyword: input,
          user_id: user.data?.id || -1,
          language: locale?.includes("en") ? "English" : "Arabic",
          country_id: country?.id,
          country_slug: country?.slug,
          from_page: "search",
          from_entity: "",
          from_entity_slug: "",
          from_page_section: action ?? "Search Box",
          from_page_section_entity: "",
          from_page_section_slug: "",
        });
        setData(res.data);
        setViewResult(true);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const handelClose = () => {
    setInputValue("");
    setData(undefined);
    setSuggestions([]);
    setViewResult(false);
    setIsLoading(false);
    setShowSearchBox(false);
    setOpenSearchBox(false);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      search(inputValue.trim());
    }
  };

  let icon;

  if (!isLoading) {
    icon = (
      <SearchIcon
        stroke="#333"
        className="cursor-pointer"
        onClick={() => setOpenSearchBox(true)}
      />
    );
  } else {
    icon = <LoadingIcon />;
  }


  let widthClass = openSearchBox && size.width > 850 ? "w-[238px] px-1" : isMobile ? "w-max" : "w-[50px]";
  let heightClass = isMobile ? "h-max" : "h-[50px] border";
  let className = `${widthClass} ${heightClass} relative rounded-xl flex justify-center items-center transition-all duration-300 ease-linear whitespace-nowrap`;

  let staticClassName = "max-w-2xl w-[calc(100vw-20px)] min-h-[322px] max-h-[600px] h-[calc(100vh-80px)] overflow-auto bg-white shadow-[0px_8px_24px_rgba(0,0,0,0.08)] rounded-2xl  absolute z-50 border border-[#eee] pt-3";
  let positionClass = isMobile
    ? locale?.includes("ar-")
      ? "-left-[40px] top-9"
      : "-right-[40px] top-9"
    : locale?.includes("ar-")
      ? "right-0 top-14"
      : "left-0 top-14";

  let className2 = ` ${staticClassName} ${positionClass}`;



  return (
    <div
      className={className}
      onClick={() => {
        size.width < 850 && fetchTerms();
      }}
      ref={wrapperRef}
    >
      {size.width > 850 ? (
        !openSearchBox && (
          <SearchIcon
            stroke="#333"
            className=" cursor-pointer"
            onClick={() => setOpenSearchBox(true)}
          />
        )
      ) : icon}

      {openSearchBox && size.width > 850 && (
        <div className="w-full bg-[#f4f4f4] h-[40px] rounded-xl flex flex-nowrap items-center justify-between px-2">
          <input
            type="search"
            value={inputValue}
            className="w-[85%] bg-[#f4f4f4] h-[40px] p-1 rounded-xl border-0 outline-none text-sm "
            placeholder={t("client_search_searchanything")}
            required
            onClick={() => fetchTerms()}
            onChange={(e) => {
              setInputValue(e.target.value);
              debounceSearch(e.target.value.trim());
            }}
            onKeyPress={(event: any) => handleKeyPress(event)}
          />
          {!isLoading ? (
            <BsSearch size={18} className=" mx-1" />
          ) : (
            <LoadingIcon />
          )}
        </div>
      )}
      {showSearchBox ? (
        <div
          className={className2}
        >
          {size.width < 850 && (
            <div className="w-full px-3 mb-3">
              <div className="w-full bg-[#f4f4f4] h-[45px] rounded-xl flex flex-nowrap items-center justify-between px-2">
                <input
                  type="search"
                  value={inputValue}
                  className="flex-1 bg-[#f4f4f4] h-[45px] p-1 rounded-xl border-0 outline-none text-sm"
                  placeholder={t("client_search_searchanything")}
                  required
                  onClick={() => {
                    size.width > 850 && fetchTerms();
                  }}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    debounceSearch(e.target.value.trim());
                  }}
                  onKeyPress={(event: any) => handleKeyPress(event)}
                />
                {!isLoading ? (
                  <BsSearch size={18} className=" mx-1" />
                ) : (
                  <LoadingIcon />
                )}
              </div>
            </div>
          )}
          {viewResult && data && inputValue ? (
            data.items.length ? (
              data.items.map((item) => (
                <div className="w-full" key={`termDetail-${item.type}`}>
                  {item.type === "suggestion" && (
                    <div className=" w-full mb-3 px-3">
                      {item.items.map((termItem) => (
                        <p
                          key={`termItem-${termItem.name}`}
                          className=" flex items-center font-normal text-xs text-[#333] mb-1 cursor-pointer "
                          onClick={() => {
                            setInputValue(termItem.name);
                            search(termItem.name);
                          }}
                        >
                          <SearchIcon className=" px-1" />
                          <span className="whitespace-nowrap overflow-hidden text-ellipsis flex-1">
                            {termItem.name}
                          </span>
                        </p>
                      ))}
                    </div>
                  )}

                  {item.type === "banner" && (
                    <div className=" w-full mb-3 px-3">
                      <Image src={item.image_url || ""} width={800} height={344} className="min-w-full" alt='bear' />
                    </div>
                  )}

                  {item.type === "product" && (
                    <div className=" w-full mb-3 overflow-hidden px-2">
                      <p className=" font-normal text-sm text-[#333] pb-2 px-2 whitespace-nowrap overflow-hidden text-ellipsis">{`${item.header.name}`}</p>
                      <div className="w-full pb-3">
                        <Carousel
                          gap={10}
                        >
                          {item.items.map((product) => (
                            <SwiperSlide key={product.default_sku_id} className="w-max">
                              <CardProduct
                                searchAction={() => {
                                  handelClose();
                                }}
                                product={product}
                                width={180}
                                source="search"
                                section={"Search Results"}
                                q={inputValue}
                              />
                            </SwiperSlide>
                          ))}
                          {item.header.more && (
                            <SwiperSlide className="w-max ">
                              <div className="sm:mx-0 xs:mx-auto mx-auto ">
                                <Link
                                  href={`/product?input=${inputValue}`}
                                  passHref
                                  prefetch={false}
                                >
                                  <a onClick={handelClose} className="">
                                    <div className=" min-w-[104px] w-[104px] min-h-[296px]  p-3 rounded-2xl border border-[#eee]  flex justify-center items-center flex-col">
                                      <PathIcon className="mb-3" />{" "}
                                      <p className=" text-[#008AF6] font-normal text-sm">
                                        {item.header.more.name}
                                      </p>
                                    </div>
                                  </a>
                                </Link>
                              </div>
                            </SwiperSlide>
                          )}
                        </Carousel>
                      </div>
                    </div>
                  )}

                  {item.type === "category" && (
                    <div className=" w-full mb-3 overflow-hidden px-2">
                      <p className=" font-normal text-sm text-[#333] pb-2 px-2 whitespace-nowrap overflow-hidden text-ellipsis">{`${item.header.name}`}</p>
                      <div className="w-full pb-3">
                        <Carousel>
                          {item.items.map((category) => (
                            <SwiperSlide
                              key={`category-${category?.id}`}
                              className="w-max"
                            >
                              <Link
                                href={`/product?category_id=${category?.id}`}
                                passHref
                                prefetch={false}
                              >
                                <a
                                  onClick={() => {
                                    segmentEvent("Category Viewed", {
                                      user_id: user.data?.id || -1,
                                      country_id: country?.id,
                                      country_slug: country?.slug,
                                      language: locale?.includes("en") ? "English" : "Arabic",
                                      category_id: category.id,
                                      category_slug: category.slug,
                                      from_page: "search",
                                      from_entity: "",
                                      from_entity_slug: "",
                                      from_page_section: "",
                                      from_page_section_entity: "",
                                      from_page_section_slug: "",
                                    });
                                    handelClose()
                                  }}
                                >
                                  <div className="min-w-[152px] flex justify-center items-end h-[160px] px-1">
                                    <div
                                      className={`w-[144px] h-[104px]  rounded-2xl `}
                                      style={{
                                        backgroundColor: category.color,
                                      }}
                                    >
                                      <div className="w-full h-[50px] relative">
                                        <div className=" absolute bottom-0 left-[22px]">
                                          <div className=" w-[100px] h-[100px] relative">
                                            <Image
                                              src={category.icon.url}
                                              alt={category.name}
                                              layout="fill"
                                              objectFit={"fill"}
                                              loading="lazy"
                                              sizes="100vw"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className=" w-full h-[50px] flex items-center justify-center">
                                        <p className=" font-normal text-sm text-[#333]">
                                          {" "}
                                          {category.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </Link>
                            </SwiperSlide>
                          ))}
                        </Carousel>
                      </div>
                    </div>
                  )}
                  {item.type === "brand" && (
                    <div className=" w-full mb-3 overflow-hidden px-2">
                      <p className=" font-normal text-sm text-[#333] pb-2 px-2 whitespace-nowrap overflow-hidden text-ellipsis">{`${item.header.name}`}</p>
                      <div className="w-full pb-3">
                        <Carousel>
                          {item.items.map((brand) => (
                            <SwiperSlide key={`brand-${brand.id}`} className="w-max">
                              <Link
                                href={`/product?brand_id=${brand.id}`}
                                passHref
                                prefetch={false}
                                key={`termBrand-${brand.id}`}
                              >
                                <a
                                  onClick={() => {
                                    segmentEvent("Brand Viewed", {
                                      user_id: user.data?.id || -1,
                                      country_id: country?.id,
                                      country_slug: country?.slug,
                                      language: locale?.includes("en") ? "English" : "Arabic",
                                      brand_id: brand.id,
                                      brand_slug: brand.slug,
                                      from_page: "search",
                                      from_entity: "",
                                      from_entity_slug: "",
                                      from_page_section: "",
                                      from_page_section_entity: "",
                                      from_page_section_slug: "",
                                    });
                                    handelClose()
                                  }}
                                >
                                  <div className="min-w-[88px] h-[88px] relative border rounded-2xl overflow-hidden mx-1">
                                    <div className="h-full w-full relative   overflow-hidden  flex items-center justify-center  scale-1  transition-transform duration-200 ease-in-out hover:scale-[1.05]">
                                      <Image
                                        src={brand.media.url}
                                        className=""
                                        alt={brand.name}
                                        width={75}
                                        height={75}
                                        objectFit={"fill"}
                                        placeholder={"blur"}
                                        blurDataURL={brand.media.url}
                                      />
                                    </div>
                                  </div>
                                </a>
                              </Link>
                            </SwiperSlide>
                          ))}
                        </Carousel>
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className="w-full flex flex-col justify-center items-center px-5 h-[250px]">
                <SearchIcon className="w-10 h-10" />
                <p className=" text-sm font-bold text-[#999] mb-3 w-full whitespace-normal text-center mt-2 leading-6">
                  {`Couldn't find what you're looking for. Search something else.`}{" "}
                </p>
              </div>
            )
          ) : suggestions.length && inputValue ? (
            <div className="w-full p-3 pt-1">
              {suggestions.map((term) => (
                <div className="w-full mb-3" key={`term-${term.type}-${term.layout}`}>
                  {term.header && (
                    <p className=" font-normal text-sm text-[#333] pb-2">
                      {term.header.name}
                    </p>
                  )}
                  {term.items.map((termItem) => (
                    <p
                      key={`termItem-${termItem.name}`}
                      className=" flex items-center font-normal text-xs text-[#333] mb-1 cursor-pointer"
                      onClick={() => {
                        setInputValue(termItem.name);
                        search(termItem.name);
                      }}
                    >
                      <SearchIcon className=" px-1" />
                      <span className="whitespace-nowrap overflow-hidden text-ellipsis flex-1">
                        {termItem.name}
                      </span>
                    </p>
                  ))}
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full p-3 pt-1">
              {defaultData.length
                ? defaultData.map((term) => (
                  <div className="w-full mb-3" key={`term-${term.type}-${term.header?.name}`}>
                    <p className=" font-normal text-sm text-[#333] pb-2">
                      {term.header?.name}
                    </p>
                    {term.type === "product" ? (
                      <div className="w-full pb-3">
                        <Carousel>
                          {term.items.map((product: any) => (
                            <SwiperSlide
                              key={product.default_sku_id}
                              className="w-max"
                            >
                              <CardProduct
                                searchAction={() => {
                                  handelClose();
                                  segmentEvent("Product Searched", {
                                    action: "Popular Searches",
                                    search_keyword: inputValue,
                                    user_id: user.data?.id || -1,
                                    language: locale?.includes("en") ? "English" : "Arabic",
                                    country_id: country?.id,
                                    country_slug: country?.slug,
                                    from_page: "search",
                                    from_entity: "",
                                    from_entity_slug: "",
                                    from_page_section: "Popular Searches",
                                    from_page_section_entity: "",
                                    from_page_section_slug: "",
                                  });
                                }}
                                product={product}
                                source="search"
                                section={"Popular Searches"}
                              />
                            </SwiperSlide>
                          ))}
                        </Carousel>
                      </div>
                    ) : (
                      term.items.map((termItem) => (
                        <p
                          key={`termItem-${termItem.name}-${term.e?.v}`}
                          className=" flex items-center font-normal text-xs text-[#333] mb-1 cursor-pointer"
                          onClick={() => {
                            setInputValue(termItem.name);
                            search(termItem.name, term.e?.v);
                          }}
                        >
                          <SearchIcon className=" px-1" />
                          <span className="whitespace-nowrap overflow-hidden text-ellipsis flex-1">
                            {termItem.name}
                          </span>
                        </p>
                      ))
                    )}
                  </div>
                ))
                : null}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SearchBox;
