import type {
    IDigitalGiftSkusInput,
    IDigitalGiftAddOutput,
    IDigitalGiftAddToWalletInput,
    IDigitalGiftAddToWalletOutput
} from "@types";
import gate from "../gate";
import client from "../http-clients/api";

const DigitalGiftApi = {
    getSkus: () =>
        gate.get<IDigitalGiftSkusInput, never, never>({
            client,
            url: "digitalGift/skus",
        }),

    addDigitalGift: (data: IDigitalGiftAddOutput) =>
        gate.post<any, IDigitalGiftAddOutput, never>({
            client,
            url: "digitalGift/addDigitalGift",
            data,
        }),

    addDigitalGiftToWallet: (data: IDigitalGiftAddToWalletOutput) =>
        gate.post<IDigitalGiftAddToWalletInput, IDigitalGiftAddToWalletOutput, never>({
            client,
            url: "digitalGift/addDigitalGiftToWallet",
            data,
        }),
};

export default DigitalGiftApi; 