import type { ErrorResponse } from "@types";
import axios, { AxiosRequestConfig } from "axios";
import auth from "../api/auth";
import {
  COOKIE_ACCESS_TOKEN_KEY,
  COOKIE_AREA_KEY,
  COOKIE_COUNTRY_KEY,
  COOKIE_LOCALE_KEY,
  COOKIE_LOGIN_TOKEN_KEY,
} from "@utils/constants";
import { getCookie, getLocale } from "@utils/Cookies";
import { toast } from "react-toastify";
import { getNotifyConfig, isServer } from "@utils/helpers";
import getConfig from "next/config";
import { _computeHMAC } from "@utils/crypto";
import { AppGuid } from "@utils/guid";
const { publicRuntimeConfig } = getConfig();

const client = axios.create({
  baseURL: publicRuntimeConfig.BASE_URL,
  responseType: "json",
});
client.defaults.timeout = 60000;
let interceptorId = -1;

client.interceptors.request.use(
  async function (config: AxiosRequestConfig) {
    const Cookies = getCookie();
    const token = Cookies.get(COOKIE_ACCESS_TOKEN_KEY);
    const loginToken = Cookies.get(COOKIE_LOGIN_TOKEN_KEY);
    if (!config.headers) config.headers = {};
    config.headers["Accept-Language"] = (getLocale() || Cookies.get(COOKIE_LOCALE_KEY)).split(
      "-"
    )[0];
    config.headers["lang"] = (getLocale() || Cookies.get(COOKIE_LOCALE_KEY)).split("-")[0];
    config.headers["x-token"] = loginToken ?? token ?? "";

    config.headers["Country"] = Cookies.get(COOKIE_COUNTRY_KEY) || "KW";
    config.headers["areaId"] = Cookies.get(COOKIE_AREA_KEY) || 0;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
let refreshTokenPromise: Promise<string> | null = null;

const reGetToken = async (): Promise<string> => {
  return auth
    .deviceAuth()
    .fetch()
    .then((res) => {
      const token = res.data.access_token || "";
      getCookie().set(COOKIE_ACCESS_TOKEN_KEY, token, {}, 365 * 2);
      return Promise.resolve(token);
    })
    .catch((err: ErrorResponse) => {
      return Promise.reject(err);
    });
};

client.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const Cookies = getCookie();
    const handleErrorResponse = () => {
      if ([502,503,504].includes(error?.response?.status)){
        if(isServer()) return
        window?.location?.replace('/maintenance')
        return;
      }
      toast.error(error?.response?.data?.meta?.msg || "Something Went Wrong", getNotifyConfig());
    };

    if (error.response) {
      switch (error.response.status) {
        case 504:
        case 503:
        case 502:
          handleErrorResponse();
          break;
        case 400:
          handleErrorResponse();
          break;
        case 401:
          handleErrorResponse();
          break;
        case 404:
          handleErrorResponse();
          break;
        case 409:
          handleErrorResponse();
          Cookies.remove(COOKIE_COUNTRY_KEY);
          Cookies.remove(COOKIE_AREA_KEY);
          break;
        case 403:
          Cookies.remove(COOKIE_LOGIN_TOKEN_KEY);
          const refreshToken = Cookies.get(COOKIE_ACCESS_TOKEN_KEY);
          if (refreshToken) {
            if (!refreshTokenPromise) {
              refreshTokenPromise = reGetToken()
                .then((token) => token)
                .catch((err) => Promise.reject(err))
                .finally(() => {
                  refreshTokenPromise = null;
                });
            }
            return refreshTokenPromise
              .then(async (accessToken) => {
                const requestId = AppGuid.generateReqId();
                let toCheck = requestId + accessToken;

                client.defaults.headers.common["x-token"] = accessToken;
                originalRequest.headers["X-REQID"] = requestId;
                originalRequest.headers["X-HASH"] = await _computeHMAC(
                  toCheck,
                  publicRuntimeConfig.HASH_KEY2
                );
                return client(originalRequest);
              })
              .catch((err) => {
                if (err.error.code === 403) {
                  Cookies.remove(COOKIE_ACCESS_TOKEN_KEY);
                  Cookies.remove(COOKIE_LOGIN_TOKEN_KEY);
                }
                return Promise.reject(err.error.details ? err.error.details : err);
              });
          } else {
            if (!refreshTokenPromise) {
              refreshTokenPromise = reGetToken()
                .then((token) => token)
                .catch((err) => Promise.reject(err))
                .finally(() => {
                  refreshTokenPromise = null;
                });
            }
            return refreshTokenPromise
              .then(async (accessToken) => {
                const requestId = AppGuid.generateReqId();
                let toCheck = requestId + accessToken;

                client.defaults.headers.common["x-token"] = accessToken;
                originalRequest.headers["X-REQID"] = requestId;
                originalRequest.headers["X-HASH"] = await _computeHMAC(
                  toCheck,
                  publicRuntimeConfig.HASH_KEY2
                );
                return client(originalRequest);
              })
              .catch((err) => {
                if (err.error.code === 403) {
                  Cookies.remove(COOKIE_ACCESS_TOKEN_KEY);
                  Cookies.remove(COOKIE_LOGIN_TOKEN_KEY);
                }
                return Promise.reject(err.error.details ? err.error.details : err);
              });
          }
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

export function setClientAuthInterceptorCallback(callback?: () => void) {
  if (isServer() && interceptorId >= 0) return;
  if (interceptorId >= 0) client.interceptors.response.eject(interceptorId);
  interceptorId = client.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const Cookies = getCookie();
      if (error.response?.status === 401) {
        Cookies.remove(COOKIE_LOGIN_TOKEN_KEY);
        callback?.();
      }
      return Promise.reject(error);
    }
  );
}

setClientAuthInterceptorCallback();

export default client;
