import type {
  IAddressFieldsByPhoneOutput,
  IAddressFieldsInput,
  IDynamicAddressList,
  IUserActiveOrder,
  IUserAddressInput,
  IUserAddressListInput,
  IUserAddressOutput,
  IUserProfileInput,
  IUserSendCodeInput,
  IUserVerifyCodeOutput,
} from "@types";
import gate from "../gate";
import client from "../http-clients/api";

const userApi = {
  profile: () =>
    gate.get<IUserProfileInput, never, never>({
      client,
      url: "user/me",
    }),
  setPassword: (data: { old_password?: string; new_password: string }) =>
    gate.post<any, { old_password?: string; new_password: string }>({
      client,
      url: "user/profile/set-password",
      data,
    }),
  setName: (data: { name: string }) =>
    gate.post<any, { name: string }>({
      client,
      url: "user/profile/set-name",
      data,
    }),
  setPhone: (data: { phone: string }) =>
    gate.post<any, { phone: string }>({
      client,
      url: "user/profile/set-phone",
      data,
    }),

  addressList: () =>
    gate.get<IUserAddressListInput, never, never>({
      client,
      url: "user/address",
    }),
  addAddress: (data: IUserAddressOutput) =>
    gate.post<IUserAddressInput, IUserAddressOutput, never>({
      client,
      url: "user/address/new",
      data,
    }),
  editAddress: (data: IUserAddressOutput, addressId: number) =>
    gate.post<IUserAddressInput, IUserAddressOutput, never>({
      client,
      url: `user/address/${addressId}`,
      data,
    }),
  removeAddress: (addressId: number) =>
    gate.get<never, never, never>({
      client,
      url: `user/address/${addressId}/remove`,
    }),

  addDynamicAddress: (data: { items: { key: string; value: any }[] }) =>
    gate.post<IAddressFieldsInput, any, never>({
      client,
      url: "user/dynamicaddress/new",
      data,
    }),
  editDynamicAddress: (
    data: { items: { key: string; value: any }[] },
    id: number
  ) =>
    gate.post<IAddressFieldsInput, any, never>({
      client,
      url: `user/dynamicaddress/${id}`,
      data,
    }),
  dynamicAddressList: () =>
    gate.get<IDynamicAddressList, never, never>({
      client,
      url: "user/dynamicaddress",
    }),
  removeDynamicAddress: (addressId: number) =>
    gate.get<never, never, never>({
      client,
      url: `user/dynamicaddress/${addressId}/remove`,
    }),

  addAddressByPhone: (data: IAddressFieldsByPhoneOutput) =>
    gate.post<IAddressFieldsInput, any, never>({
      client,
      url: "user/dynamicaddress/new/by-phone",
      data,
    }),
  activeOrders: () =>
    gate.get<IUserActiveOrder, any, never>({
      client,
      url: "user/order/active",
    }),
  getUserPhoneNumber: () =>
    gate.get<{ phone: string }, any, never>({
      client,
      url: "user/dynamicaddress/phone",
    }),
    sendCode: (data: { phone: string }) =>
      gate.post<IUserSendCodeInput, { phone: string }, never>({
        client,
        url: "user/add-phone/send-code",
        data,
      }),
    verifyCode: (data: IUserVerifyCodeOutput) =>
      gate.post<IUserProfileInput, IUserVerifyCodeOutput, never>({
        client,
        url: "user/add-phone/verify-code",
        data,
      }),
    suggestPhone: (params?: { address_id: number }) =>
      gate.get<{ phone: string }, never, undefined | { address_id: number }>({
        client,
        url: "user/add-phone/suggest",
        params,
      }),
};

export default userApi;
