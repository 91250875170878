import Link from "next/link";
import Image from "next/image";
import { useEffect, useState } from "react";
import { IAreaDetailInput, ICountryInput } from "@types";
import InstagramIcon from "@svg/instagramIcon";
import TwitterIcon from "@svg/twitterIcon";
import FacebookIcon from "@svg/facebookIcon";
import LinkedinIcon from "@svg/linkedinIcon";
import { useAppContext } from "@context/app";
import { getCookie } from "@utils/Cookies";
import { COOKIE_COUNTRY_KEY } from "@utils/constants";
import { useRouter } from "next/router";
import { toArabicNumber } from "@utils/digits";
import getConfig from "next/config";
import { useTranslations } from "next-intl";

const { publicRuntimeConfig } = getConfig();

interface Props {
  countries: ICountryInput[];
  area?: IAreaDetailInput;
}

const Footer: React.FC<Props> = ({ countries, area }) => {
  const { locale } = useRouter();
  const t = useTranslations()
  const { setUserCountry, setUserArea, country, setUserFreeShipping } = useAppContext();
  const Cookies = getCookie();
  const countryCode = Cookies.get(COOKIE_COUNTRY_KEY);
  const [countryList, setCountryList] = useState<ICountryInput[]>([]);
  useEffect(() => {
    if (countries.length) {
      setCountryList(countries)
      const selectedCountry = countries.find((c) => c.code2 === countryCode)
      setUserCountry(selectedCountry);
      setUserFreeShipping(selectedCountry?.freeshipping || null)
    }
    if (area) {
      setUserArea(area)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, area])
  return (
    <footer className={`w-full lg:p-5 p-0 pt-5 `}>
      <div className=" w-full md:pb-12 pb-8 md:pt-16 pt-8 xl:px-[100px] lg:px-5 px-4 bg-[#F8F9FB] lg:rounded-t-[20px] ">
        <div className=" max-w-[1240px] m-auto flex flex-wrap justify-between">
          <div className="lg:flex-1 lg:max-w-[280px] sm:w-1/2  w-full lg:mb-0 mb-16">
            <div className=" w-full mb-4">
              <p className=" font-normal text-base text-[#333]">
                {t("client_footer_dabdoobapp")}
              </p>
            </div>
            <div className="w-full mb-5">
              <p className="text-sm font-normal text-textLight">
                {t("client_footer_dabdoobappdescription")}
              </p>
            </div>
            <div className="w-full flex flex-wrap">
              <Link
                href="https://apps.apple.com/us/app/dabdoob/id1288684251"
                passHref
                prefetch={false}
              >
                <a
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <div className="relative z-[1] p-1">
                    <Image
                      src={"/imgs/appStore.webp"}
                      className="cursor-pointer "
                      alt={"dabdoob ios Application"}
                      width={120}
                      height={40}
                      quality={100}
                      objectFit={"fill"}
                    />
                  </div>
                </a>
              </Link>
              <Link
                href="https://play.google.com/store/apps/details?id=com.dabdoobapp.android"
                passHref
                prefetch={false}
              >
                <a
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <div className="relative z-[1] p-1">
                    <Image
                      src={"/imgs/googleStore.webp"}
                      className="cursor-pointer"
                      alt={"dabdoob android application"}
                      width={135}
                      height={40}
                      quality={100}
                      objectFit={"fill"}
                    />
                  </div>
                </a>
              </Link>
            </div>
          </div>
          <div className="lg:flex-1 xl:max-w-[220px] sm:px-2 px-0 sm:w-1/2 w-full lg:mb-0 mb-16">
            <div className=" w-full mb-4">
              <p className=" font-normal text-base text-[#333]">
                {t("client_footer_followus")}
              </p>
            </div>
            <div className="w-full mb-5">
              <p className="text-sm font-normal text-textLight">
                {t("client_footer_followusdescription")}
              </p>
            </div>
            <div className="w-full flex flex-wrap">
              <span className="mx-1">
                <Link
                  href={"https://www.instagram.com/dabdoobapp"}
                  passHref
                  prefetch={false}
                >
                  <a target={"_blank"} rel="noopener noreferrer" aria-label="Instagram">
                    <InstagramIcon />
                  </a>
                </Link>
              </span>
              <span className="mx-1">
                <Link
                  href={"https://twitter.com/dabdoobapp"}
                  passHref
                  prefetch={false}
                >
                  <a target={"_blank"} rel="noopener noreferrer" aria-label="Twitter">
                    <TwitterIcon />
                  </a>
                </Link>
              </span>
              <span className="mx-1">
                <Link
                  href={"https://www.facebook.com/Dabdoobapp/"}
                  passHref
                  prefetch={false}
                >
                  <a target={"_blank"} rel="noopener noreferrer" aria-label="Facebook">
                    <FacebookIcon />
                  </a>
                </Link>
              </span>
              <span className="mx-1">
                <Link
                  href={"https://www.linkedin.com/company/dabdoob"}
                  passHref
                  prefetch={false}
                >
                  <a target={"_blank"} rel="noopener noreferrer" aria-label="Linkedin">
                    <LinkedinIcon />
                  </a>
                </Link>
              </span>
            </div>
          </div>
          <div className="lg:w-max sm:w-1/2 w-full  lg:mb-0 mb-16">
            <div className=" w-full mb-4">
              <p className=" font-normal text-base text-[#333]">
                {t("client_footer_legal")}
              </p>
            </div>
            <div className="w-full flex flex-col space-y-3">
              <a target="_blank" rel="noopener noreferrer" className={`font-normal text-sm text-textLight hover:text-textLight leading-5 `} href={locale?.includes("ar") ? country?.staticpages_ar.terms || "/" : country?.staticpages_en.terms || "/"}>{t("client_general_termsandconditios")}</a>
              <a target="_blank" rel="noopener noreferrer" className={`font-normal text-sm text-textLight hover:text-textLight leading-5 `} href={locale?.includes("ar") ? country?.staticpages_ar["privacy-policy"] || "/" : country?.staticpages_en["privacy-policy"] || "/"}>{t("client_general_privacypolicy")}</a>
              <a target="_blank" rel="noopener noreferrer" className={`font-normal text-sm text-textLight hover:text-textLight leading-5 `} href={locale?.includes("ar") ? country?.staticpages_ar["return-and-refund"] || "/" : country?.staticpages_en["return-and-refund"] || "/"}>{t("client_general_returnandrefundpolicy")}</a>
            </div>
          </div>
          <div className="lg:flex-1 lg:max-w-[340px] sm:w-1/2 w-full flex flex-wrap sm:px-2 px-0">
            <div className=" w-full mb-4">
              <p className="font-normal text-base text-[#333]">
                {t("client_footer_contact")}
              </p>
            </div>
            {countryList.map((country) => (
              <div
                className=" sm:w-1/2 xs:w-1/2 w-full mb-5 flex flex-wrap items-center gap-1"
                key={`country-footer-${country.id}`}
              >
                <div className=" w-6 h-6 relative rounded-full overflow-hidden">
                  <Image
                    src={country.image.url}
                    layout="fill"
                    alt={country.name}
                  />
                </div>
                <div className=" flex-1">
                  <p className=" font-normal text-xs text-textLight">{`${country.name}`}</p>
                  <p className={`font-normal text-xs text-textLight ${locale?.includes("ar-") ? "text-right" : "text-left"}`} dir="ltr">{`${locale?.includes("ar")
                    ? toArabicNumber(country.contact)
                    : country.contact
                    }`}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full py-5 xl:px-[100px] lg:px-5 px-5 bg-[#F3F4F6] lg:rounded-b-[20px]">
        <div className=" flex justify-between items-center flex-wrap w-full  max-w-[1240px] m-auto">
          <div className="mb-0 text-xs font-normal text-[#333] flex flex-wrap sm:justify-between xs:justify-center justify-center w-full items-center">
            <p className="sm:w-max xs:w-full w-full text-center">
              Dabdoob. &copy; {new Date().getFullYear()}. All Rights Reserved
            </p>
            <p className="text-xs font-normal text-[#333]">
              V{publicRuntimeConfig.appVersion}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
